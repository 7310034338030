window.accordion = function () {
  // Todo: replace with alpine?
  const accordionHeaders = document.querySelectorAll('[data-accordion-header]');

  accordionHeaders.forEach(accordionHeader => {
    const target = accordionHeader.parentElement.nextElementSibling;

    accordionHeader.onclick = (event) => {
      event.preventDefault();
      let expanded = accordionHeader.getAttribute('aria-expanded') === 'true' || false;

      accordionHeader.setAttribute('aria-expanded', !expanded);
      // Todo: Add animations?
      target.hidden = expanded;
    }
  });
}

