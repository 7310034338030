console.debug('building');
import './../scss/app.scss';

import Alpine from 'alpinejs'
import collapse from '@alpinejs/collapse'

window.Alpine = Alpine
Alpine.plugin(collapse)
Alpine.start()

import './modules/sliders.js'
import './modules/productSlider.js'
import './modules/accordion.js'
import './modules/addresses.js'
import './modules/filter.js'
import './modules/header.js'
import './modules/modal.js'
import './modules/notifications.js'
import './modules/productNav.js'
import './modules/quickReplace.js'
import './modules/homepage.js'
import './modules/turnstile.js'

// Init sliders
window.sliders()
window.productSlider()
window.accordion();