window.addresses = function () {
    return {
        modelId: null,
        countryId: null,
        modelName: null,
        postalCode: '',
        businessTaxId: '',
        appLanguage: '',
        customerType: '',
        errors: {},
        onChange(ev) {
            this.countryId = ev.target.value;
        },
        getErrors(key) {
            if (!Object.keys(this.errors).length || Object.keys(this.errors).indexOf(key) === -1) {
                return false;
            }

            return this.errors[key];
        },
        hasErrors(key) {
            let errors = this.getErrors(key);
            return errors && errors.length;
        },
        updateErrors(errors) {
            this.errors = errors;
        },
        async validateVATNumber(id) {
            const url = `https://controleerbtwnummer.eu/api/validate/${this.businessTaxId}.json`;
            const businessTaxElement = document.getElementById(`${id}-businessTaxId`);
            const nextButton = document.querySelector('input#next');

            if (this.businessTaxId.length) {
                const response = await fetch(url);
                const { valid } = await response.json();

                if (!valid) {
                    businessTaxElement.classList.add('border-red-600');
                    nextButton.classList.remove('button--primary');
                    nextButton.setAttribute('disabled', '');

                    return;
                }
            }
            businessTaxElement.classList.remove('border-red-600');
            nextButton.classList.add('button--primary')
            nextButton.removeAttribute('disabled');
        },
        async validateZipCode(idName) {
            if (this.appLanguage !== 'nl') {
                return;
            }
            if (this.postalCode) {
                let API_KEY = 'CC8lNMqVA3aMFT3aPSq8Dv8hLwOEER5N';
                let url = "https://api.tomtom.com/search/2/structuredGeocode.json?countryCode=NL&limit=10&postalCode=" + this.postalCode.replace(/\s/g, '') + "&key=" + API_KEY;
                // Storing response
                const response = await fetch(url);
                // Storing data in form of JSON
                let data = await response.json();
                if (data.results) {
                    data.results.forEach(function formatData(item, index) {
                        if (item.type === 'Street') {
                            document.getElementById(idName + "-locality").value = item.address.localName;
                            document.getElementById(idName + "-addressLine1").value = item.address.streetName;
                        }
                    });
                }
            }
        }
    };
};
