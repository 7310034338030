import Swal from 'sweetalert2';

(function() {
    const url = new URL(window.location.href)
    const searchParams = new URLSearchParams(window.location.search);

    url.searchParams.delete('success')
    window.history.replaceState({}, document.title, url)

    if(searchParams.has('success')) {
        switch (searchParams.get('success')) {
            case 'account_activated':
                Swal.fire({
                    title: 'Registratie voltooid!',
                    html: 'Het is gelukt om jouw registratie te voltooien, je kunt nu <a href="/account/login">inloggen</a>.',
                    icon: 'success',
                });
                break;
            case 'password_set':
                Swal.fire({
                    title: 'Wachtwoord reset gelukt!',
                    html: 'Het is gelukt om jouw wachtwoord te resetten, je kunt nu <a href="/account/login">inloggen</a>.',
                    icon: 'success',
                });
                break;
        }

    }
})();

