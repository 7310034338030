import Swiper, {Navigation, Thumbs} from 'swiper';

Swiper.use([Navigation, Thumbs]);

window.productSlider = function () {
  let thumbsSlider = null;
  if (document.getElementsByClassName('js-product-gallery-thumbs-slider').length) {
    thumbsSlider = new Swiper('.js-product-gallery-thumbs-slider', {
      spaceBetween: 10,
      slidesPerView: 4,
      freeMode: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
    });
  }
  const slider = new Swiper('.js-product-gallery-slider', {
    autoHeight: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    thumbs: {
      swiper: thumbsSlider,
    }
  });
};



