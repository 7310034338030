window.filterPreviousState = {
    initialCategorySlug: '',
    categorySlug: '',
    filterAttributes: [],
    sort: '',
    limit: '',
    currentPage: '',
};
window.filterPreviousStates = [];

window.filter = function () {
    return {
        showFilters: false,
        categorySlug: '',
        initialCategorySlug: '',
        filterAttributes: [],
        sort: '',
        limit: '',
        currentPage: '',
        _nt: null,
        state: {},
        saveState: true,
        focusElement: null,
        categoriesFilterCollapsed: true,

        init(state, $nextTick) {

            let _this = this;
            this._nt = $nextTick;
            if (typeof state !== "undefined") {
                this.initialCategorySlug = state.initialCategorySlug;
            }
            this.setFromState(state);

            // Using window variables to avoid alpine proxying all the data
            window.addEventListener('saveFilterState', function (ev) {
                window.filterPreviousStates.push(window.filterPreviousState);
                window.filterPreviousState = ev.detail;
            });

            window.addEventListener('popstate', function (e) {
                if (window.filterPreviousStates.length) {
                    const filterPreviousState = window.filterPreviousStates.pop();
                    e.preventDefault();
                    _this.setFromState(filterPreviousState);
                    _this.saveState = false;
                    _this.refresh();
                }
            });

            htmx.on('htmx:afterSwap', function (event) {
                if (event.detail.target.getAttribute('id') == 'filter') {
                    _this._nt(function () {
                        var el = _this.focusElement ? document.querySelector('#' + _this.focusElement) : null;
                        // Re-focus the element that made the call.
                        if (el) {
                            el.focus();
                            _this.focusElement = null;
                        }
                    });
                }
            });
        },

        setFromState(state) {
            if (typeof state !== "undefined") {
                this.categorySlug = state.categorySlug;
                this.filterAttributes = state.filterAttributes;
                this.sort = state.sort;
                this.limit = state.limit;
                this.currentPage = state.currentPage;
            }
        },

        toggle(key, value, $event) {
            var idx = this[key].indexOf(value);
            if (idx == -1) {
                this[key].push(value);
            } else {
                if (this[key].length == 1) {
                    this[key] = [];
                } else {
                    this[key] = this[key].filter((item, index) => index !== idx);
                }
            }
            this.refresh();
        },

        clear() {
            this.categorySlug = this.initialCategorySlug;
            this.filterAttributes = [];
            this.sort = '';
            this.limit = '';
            this.currentPage = 1;
            this.refresh();
        },

        setType(val, $event) {
            var el = $event.target || $event.srcElement;

            this.focusElement = el.id;
            this.categorySlug = val == this.categorySlug ? '' : val;

            this.refresh();
        },

        refresh() {
            var _this = this;
            // always hide mobile filter list when selecting a filter
            this.showFilters = false;

            this._nt(function () {
                htmx.trigger(htmx.find('#filter'), 'refresh');
            });
        }
    };
};
